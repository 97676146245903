@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

@import "./animations.scss";

@font-face {
  font-family: "Feijoa Medium";
  src: url("../../public/fonts/feijoa-medium.woff2");
}

@font-face {
  font-family: "Work Sans";
  font-stretch: 100%;
  font-style: normal;
  font-weight: 100 600;
  src: url("../../public/fonts/WorkSans-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-stretch: normal;
  font-style: italic;
  font-weight: 100 600;
  src: url("../../public/fonts/WorkSans-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@layer base {
  :root {
    --color-text: #000000;
  }
}

svg.opened {
  transform: rotate(180deg);
  transition-duration: 300ms;
}

svg.closed {
  transform: rotate(0deg);
  transition-duration: 300ms;
}

.secondary-svg path {
  stroke: #006f72;
}
.secondary-svg:hover path {
  stroke: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 36px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

html, body {
  overscroll-behavior: none;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 49px;
  border: 1px solid #cbd5e0;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #576477;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  background-color: #006f72;
}

input:focus + .slider {
  box-shadow: 0 0 1px #006f72;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.main-content-height {
  min-height: calc(100dvh - 104px - 128px - 20px);
}

@media (min-width: 1024px) {
  .main-content-height {
    min-height: calc(100dvh - 108px - 160px);
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 40px;
  padding-right: 20px;
  margin-bottom: 12px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  left: 12px;
  height: 20px;
  width: 20px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #006f72;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #006f72;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #006f72;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disable-highlight {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.modal-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1c60;
}

.modal-content {
  overflow: auto;
}

.absolute-center {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
/* 3rd parties */

/* collapse */
.collapse-css-transition {
  transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}
/* collapse */

/** nprogress **/
#nprogress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #edeef1;
  height: 4px;
}

/** custom scrollbar **/
.simplebar-scrollbar::before {
  background-color: white !important;
  width: 10px;
  left: 0 !important;
  top: 0 !important;
  cursor: pointer !important;
  opacity: 1 !important;
}
.simplebar-wrapper {
  z-index: -1 !important;
}
.simplebar-track {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  width: 10px !important;
  z-index: 10 !important;
  cursor: pointer !important;
}
.simplebar-placeholder {
  width: 100% !important;
}

/** dialog **/
.rounded-dialog {
  border-radius: 20px;
}

/* width */
.overflow-y-auto::-webkit-scrollbar {
  width: 25px;
}

/* Track */
.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.overflow-y-auto::-webkit-scrollbar-thumb {
  width: 5px;
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color:#B2BBC7;
  cursor: pointer;
}

/* Handle on hover */
.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: #8996A7;
  // width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  border: 8px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #F6F7F9;
}
/* 3rd parties */