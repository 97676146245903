.navbar-container-animation-appear {
  opacity: 0;
}
.navbar-container-animation-appear-active {
  opacity: 1;
  transition: opacity 2s;
}
.navbar-container-animation-enter {
  transform: translate3d(0px, -10px, 0px);
  opacity: 0;
}
.navbar-container-animation-enter-active {
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
}
.navbar-container-animation-exit {
  opacity: 1;
}
.navbar-container-animation-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.page-animation-appear {
  opacity: 0;
}
.page-animation-appear-active {
  opacity: 1 !important;
  transition: opacity 2s;
}
.page-animation-enter {
  opacity: 1;
  & > * > * > * {
    opacity: 0;
    transform: translate3d(0px, 10px, 0px);
  }
}
.page-animation-enter-active {
  opacity: 1;
  & > * > * > * {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);

    @for $i from 0 through 40 {
      &:nth-child(#{$i}) {
        transition:
          transform 0.5s ease-in-out ($i * 0.1s),
          opacity 0.3s linear ($i * 0.1s);
      }
    }
  }
}
.page-animation-exit {
  opacity: 1;
}
.page-animation-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.rewards-bar-animation-appear {
  opacity: 0;
}
.rewards-bar-animation-appear-active {
  opacity: 1 !important;
  transition: opacity 2s;
}
.rewards-bar-animation-enter {
  height: 0 !important;
}
.rewards-bar-animation-enter-active {
  transition: height 0.3s ease-out;
  height: 40px !important;
}
.rewards-bar-animation-exit {
  height: 40px !important;
}
.rewards-bar-animation-exit-active {
  height: 0 !important;
  transition: height 0.3s ease-out;
}

.slide-in-enter {
  opacity: 0;
  transform: translate3d(0px, 10px, 0px);
}
.slide-in-enter-active {
  transition:
    transform 0.3s ease-out,
    opacity 0.2s linear;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.footer-container-animation-appear {
  opacity: 0;
}
.footer-container-animation-appear-active {
  opacity: 1;
  transition: opacity 0.5s;
}
.footer-container-animation-enter {
  transform: translate3d(0px, 10px, 0px);
  opacity: 0;
}
.footer-container-animation-enter-active {
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
}
.footer-container-animation-exit {
  opacity: 1;
}
.footer-container-animation-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.slide-in-tabs-enter {
  transform: translate3d(0px, 50px, 0px);
}
.slide-in-tabs-enter-active {
  transition:
    transform 1s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.2s linear;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

@keyframes scale-bar {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
